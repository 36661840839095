<template lang="pug">
  .sale-settings-container
    p.plan-name
      | {{ $t("plan_management.modal.plan_name") }}
      span {{ plan.name }}
    .contents
      .calendar-section
        Calendar(
          :period="[plan.sale_start_date, plan.sale_end_date]"
          :special-period="specialPeriod.period"
          :special-period-name="specialPeriod.name"
          :special-period-color="specialPeriod.laneColor"
        )
      .settings-section
        .setting-row
          p.setting-title {{ $t("plan_management.modal.jalan_sale_settings.period_name") }}
          AppInput.period-name(
            type="text"
            v-model="specialPeriod.name"
          )
          .hint
            span {{ $t("plan_management.modal.jalan_sale_settings.period_name_hint") }}
        .setting-row
          p.setting-title
            FormFieldLabel(
              :title="$t('plan_management.modal.jalan_sale_settings.period')"
              required
            )
          AppDatepicker.period(
            :value="specialPeriod.period"
            range
            @change="applyPeriod"
          )
        .setting-row
          p.setting-title {{ $t("plan_management.modal.jalan_sale_settings.classification") }}
          .classification-radio
            AppRadioButton(
              :value="specialPeriod.specialPriceEnabled"
              @change="enableSpecialPrice"
            )
            .label
              span {{ $t("plan_management.modal.jalan_sale_settings.special_price") }}
          .classification-radio
            AppRadioButton(
              :value="!specialPeriod.specialPriceEnabled"
              @change="enableSaleSuspension"
            )
            .label
              span {{ $t("plan_management.modal.jalan_sale_settings.suspension_price") }}
        .setting-row(v-if="specialPeriod.specialPriceEnabled")
          p.setting-title
            FormFieldLabel(
              :title="$t('plan_management.modal.jalan_sale_settings.price')"
              required
            )
          .price-list
            .price-item
              .label
                span {{ $t("plan_management.time_slot.daily.same_day")  }}
              AppInput.price(
                type="text"
                v-model="specialPeriod.prices['same_day']"
              )
              .yen {{ $t("general_settings.yen") }}
            .price-item
              .label
                span {{ $t("plan_management.time_slot.daily.1_night_2_days")  }}
              AppInput.price(
                type="text"
                v-model="specialPeriod.prices['1_night_2_days']"
              )
              .yen {{ $t("general_settings.yen") }}
            .price-item
              .label
                span {{ $t("plan_management.time_slot.daily.2_nights_3_days")  }}
              AppInput.price(
                type="text"
                v-model="specialPeriod.prices['2_nights_3_days']"
              )
              .yen {{ $t("general_settings.yen") }}
            .price-item
              .label
                span {{ $t("plan_management.time_slot.daily.3_night_4_days")  }}
              AppInput.price(
                type="text"
                v-model="specialPeriod.prices['3_night_4_days']"
              )
              .yen {{ $t("general_settings.yen") }}
            .price-item
              .label
                span {{ $t("plan_management.time_slot.daily.daily_thereafter")  }}
              AppInput.price(
                type="text"
                v-model="specialPeriod.prices['daily_thereafter']"
              )
              .yen {{ $t("general_settings.yen") }}
        .setting-row.no-border
          p.setting-title {{ $t("plan_management.modal.jalan_sale_settings.color_setting") }}
          .color-selection
            template(v-for="color in laneColors")
              RadioButton(
                :value="specialPeriod.laneColor === color"
                :color="color"
                @change="setLaneColor"
              )
        .setting-row
          .actions
            AppButton.delete(
              title="plan_management.modal.jalan_sale_settings.delete"
              @click="handleDeleteSettings"
            )
            AppButton.save(
              title="plan_management.modal.jalan_sale_settings.save_settings"
              @click="handleSaveSettings"
            )
</template>

<script>
  const LANE_COLORS = ["red", "blue", "yellow", "pink", "green"]
  const defaultSpecialPeriodObject = () => ({
    laneColor: "red",
    period: [],
    name: "",
    specialPriceEnabled: true,
    prices: {
      same_day: undefined,
      "1_night_2_days": undefined,
      "2_nights_3_days": undefined,
      "3_night_4_days": undefined,
      daily_thereafter: undefined
    }
  })

  export default {
    components: {
      Calendar: () => import("./Calendar"),
      RadioButton: () => import("./RadioButton"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppInput: () => import("@/components/elements/AppInput"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    data() {
      return {
        laneColors: LANE_COLORS,
        specialPeriod: {}
      }
    },

    props: {
      plan: {
        type: Object,
        required: true
      }
    },

    created() {
      this.setSpecialPeriodData()
    },

    methods: {
      setSpecialPeriodData() {
        this.specialPeriod = { ...defaultSpecialPeriodObject(), ...this.plan.specialPeriod }
      },

      setLaneColor(color) {
        this.specialPeriod.laneColor = color
      },

      applyPeriod(dateRange) {
        this.specialPeriod.period = dateRange
      },

      enableSpecialPrice() {
        this.specialPeriod.specialPriceEnabled = true
      },

      enableSaleSuspension() {
        this.specialPeriod.specialPriceEnabled = false
      },

      handleDeleteSettings() {
        this.specialPeriod = defaultSpecialPeriodObject()
      },

      handleSaveSettings() {
        this.$set(this.plan, "specialPeriod", this.specialPeriod)
        this.$emit("close")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"

  .sale-settings-container
    width: 1185px

    .plan-name
      color: $default-gray
      font-size: 1.2rem

      span
        font-size: 1.0rem
    .contents
      display: flex
      justify-content: space-between

      .calendar-section
        width: 700px

      .settings-section
        width: 440px
        margin-right: 10px
        box-shadow: 2px 2px 5px transparentize($default-gray, 0.7)

        .setting-row
          margin: 15px 30px
          padding-bottom: 10px
          border-bottom: 1px solid $default-gray-light
          opacity: 0.8

          .actions
            display: flex
            justify-content: space-around

            button
              height: 30px
              width: 180px

            .delete
              border-color: $default-red
              color: $default-red

            .save
              color: $default-white
              background-color: $default-purple

          &.no-border
            border-bottom: none

          .color-selection
            display: flex

          .price-list
            .price-item
              display: flex
              justify-content: left
              margin-bottom: 3px

              .yen
                font-size: 0.8rem
                margin-left: 5px
                margin-top: 6px

              .label
                width: 30%
                font-size: 0.9rem
                margin-right: 10px
                padding: 5px 0px

              ::v-deep
                input
                  width: 100px

          .classification-radio
            display: flex
            justify-content: left

            .app-radio-button
              margin-right: 5px
              margin-top: 3px

              ::v-deep
                .big-circle
                  border: 1px solid $default-black
                  width: 15px
                  height: 15px

                .small-circle
                  width: 8px
                  height: 8px

            .label
              font-size: 0.9rem

          .period
            width: 100%
            padding-right: 0

          .hint
            text-align: right
            color: #786d44
            font-size: 0.8rem
            font-style: normal
            font-weight: 400

          ::v-deep
            input
              width: 100%
              height: 32px
              border-color: $default-purple

          .setting-title
            color: $default-gray
            font-weight: 600
            font-size: 0.85rem
            height: 20px
            margin-bottom: 5px
</style>
