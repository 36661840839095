<template lang="pug">
  tr.plan-row
    td.sticky-col.status
      AppToggle(
        :value="plan.status"
        @change=""
      )
    td.sticky-col.ota-name
      span {{ plan.ota_name }}
    td.sticky-col.plan-name
      textarea.editable-field.form-control(
        type="text"
        :value="plan.name"
        @input=""
      )
    td.insurance
      span {{ plan.insurance }}
    td.sale-start-date
      AppDatepicker.date(:value="plan.sale_start_date")
    td.sale-stop-date
      AppDatepicker.date(:value="plan.sale_end_date")
    td.posting-start-date
      AppDatepicker.date(:value="plan.posting_start_date")
    td.posting-end-date
      AppDatepicker.date(:value="plan.posting_end_date")
    td.car-class(:colspan="3")
      template(v-for="car_class in plan.car_classes")
        table.car-class-table
          tr.no-border(:class="{ 'added': car_class._addedAt }")
            td.car-class(:rowspan="car_class.price_items.length + 2")
              span {{ car_class.name }}
          template(v-for="(price_item, index) in car_class.price_items")
            tr(:class="{ 'added': price_item._addedAt }")
              td.base-rate-period-group
                span {{ price_item.base_rate_period_group_name }}
              td.prices
                PricesRow(
                  :prices="price_item.prices"
                  @remove-row="removePriceItem(car_class, index)"
                )
                AppButton.add-button.jalan-sale-settings(
                  v-if="plan.ota_id === jalanOtaId"
                  title="plan_management.actions.jalan_sale_settings",
                  @click="handleJalanSaleSettings(plan)"
                )
          tr(
            v-if="plan.ota_id === rakutenOtaId"
            :class="{ 'added': car_class._addedAt }"
          )
            td
              AppIconButton.add-button(
                icon="plus-circle"
                title="plan_management.actions.add_base_rate_period_group",
                @click="handleAddBaseRatePeriodGroup(car_class)"
              )
            td
      tr.no-border(v-if="plan.ota_id === rakutenOtaId")
        td
          AppIconButton.add-button(
            icon="plus-circle"
            title="plan_management.actions.add_car_class",
            @click="handleAddCarClass(plan)"
          )
        td
</template>

<script>
  //components
  import JalanSaleSettingsModal from "./JalanSaleSettingsModal"

  // mixins
  import withModal from "@/mixins/withModal"

  import { PRICE_MANAGEMENT_ALLOWED_OTA_IDS } from "@/config/constants"

  export default {
    components: {
      PricesRow: () => import("./PricesRow"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker")
    },

    mixins: [withModal],

    props: {
      plan: {
        type: Object,
        required: false
      }
    },

    computed: {
      jalanOtaId() {
        return PRICE_MANAGEMENT_ALLOWED_OTA_IDS.jalanId
      },

      rakutenOtaId() {
        return PRICE_MANAGEMENT_ALLOWED_OTA_IDS.rakutenId
      }
    },

    methods: {
      removePriceItem(carClass, priceItemIndex) {
        carClass.price_items.splice(priceItemIndex, 1)
      },

      handleJalanSaleSettings(plan) {
        this.$openModal({
          component: JalanSaleSettingsModal,
          title: this.$t("plan_management.actions.jalan_sale_settings"),
          closeOnClick: true,
          size: "large",
          props: {
            plan
          }
        })
      },

      handleAddCarClass(plan) {
        this.$openModal({
          component: () => import("./AddCarClassModal"),
          title: this.$t("plan_management.actions.add_car_class"),
          closeOnClick: true,
          props: {
            plan
          }
        })
      },

      handleAddBaseRatePeriodGroup(carClass) {
        this.$openModal({
          component: () => import("./AddBaseRatePeriodGroupModal"),
          title: this.$t("plan_management.actions.add_base_rate_period_group"),
          closeOnClick: true,
          props: {
            carClass
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/matchings.sass"

  .sticky-col
    +sticky-col
    background-color: $default-white
  tr
    border-bottom: 1px solid $default-gray-light

    &.no-border
      border-bottom: none

    .add-button
      background-color: $default-purple

      &.jalan-sale-settings
        margin: 10px 5px
        padding: 5px 10px

      ::v-deep
        svg
          path
            fill: $default-white
        .title
          color: $default-white
  td
    font-size: 0.8rem
    padding: 10px 10px
    vertical-align: top

    .date
      width: auto

      ::v-deep
        input.mx-input
          height: 30px
          border-color: $default-purple

    &.status
      left: 0
      z-index: 10
    &.ota-name
      left: 72px
      z-index: 10
    &.plan-name
      left: 144px
      z-index: 10
      ::v-deep
        textarea
          font-size: 0.9rem
          border-color: $default-purple
          min-height: 200px
          vertical-align: top

  .price-row
    display: flex


  .car-class-table
    width: 1000px

    tr
      &.added
        background-color: $default-purple-light
        border-color: $default-purple-light
      td
        text-align: left

        &.car-class
          width: 120px

        &.base-rate-period-group
          width: 240px

        &.prices
          padding-top: 0

          svg
            cursor: pointer
</style>
